<template>
  <div>
    <v-form>
      <p class="mb-3">
        <chemical-latex content="50.00 \text{mL}" />
        of
        <chemical-latex content="0.6000M C2H5NH2 (Kb :6.4 x 10-4)" />
        was titrated with
        <chemical-latex content="0.62000 M HNO3\text{.}" />
        Calculate the pH at the following volumes of added
        <chemical-latex content="HNO3\text{.}" />
      </p>

      <p v-for="option in options" :key="option.inputIndex">
        <stemble-latex :content="option.text" />
        <calculation-input v-model="inputs[option.inputIndex]" :disabled="!allowEditing" />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default {
  name: 'ChemUPEI2210A5_Q5',
  components: {CalculationInput, ChemicalLatex, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswerA: null,
        studentAnswerB: null,
        studentAnswerC: null,
        studentAnswerD: null,
        studentAnswerE: null,
      },
      options: [
        {
          text: '$\\ce{a) 0.00 mL}$',
          inputIndex: 'studentAnswerA',
        },
        {
          text: '$\\ce{b) 25.00 mL}$',
          inputIndex: 'studentAnswerB',
        },
        {
          text: '$\\ce{c) 40.00 mL}$',
          inputIndex: 'studentAnswerC',
        },
        {
          text: '$\\ce{d) 50.00 mL}$',
          inputIndex: 'studentAnswerD',
        },
        {
          text: '$\\ce{e) 70.00 mL}$',
          inputIndex: 'studentAnswerE',
        },
      ],
    };
  },
};
</script>
